import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 84;
  }

  public createContent(): any {
    const res = new FormModel.Icf.Core();
    res.values.create_date = DateUtil.currentDateStr();
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {}

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);

    const elements_t = [
      {
        name: "基本情報",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              作成者
              {saV.text("sakuseisya")}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              氏名
              {saV.text("name")}
            </label>
          </div>
        ),
      },
      {
        name: "内容",
        content: (
          <div style={{ padding: 10 }}>
            <h3>健康状態</h3>
            <section>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 10,
                }}
              >
                疾患
                {saV.text("shikkan")}
              </label>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 10,
                }}
              >
                廃用症候群
                {saV.text("haiyou")}
              </label>
            </section>
            <h3>心身機能</h3>
            <section>
              <label
                style={{
                  display: "block",
                  marginBottom: 10,
                }}
              >
                {saV.text(`shinshinkinou`, "", "text", "block", true, "100px")}
              </label>
              <label>
                機能・構造障害
                {saV.text(`syougai`, "", "text", "block", true, "100px")}
              </label>
            </section>
            <h3>活動</h3>
            <section>
              <label
                style={{
                  display: "block",
                  marginBottom: 10,
                }}
              >
                できる活動
                {saV.text(`dekiru`, "", "text", "block", true, "100px")}
              </label>
              <label
                style={{
                  display: "block",
                  marginBottom: 10,
                }}
              >
                している活動
                {saV.text(`shiteiru`, "", "text", "block", true, "100px")}
              </label>
              <label
                style={{
                  display: "block",
                  marginBottom: 10,
                }}
              >
                活動制限
                {saV.text(`seigen`, "", "text", "block", true, "100px")}
              </label>
            </section>
            <h3>参加</h3>
            <section>
              <label
                style={{
                  display: "block",
                  marginBottom: 10,
                }}
              >
                {saV.text(`sanka`, "", "text", "block", true, "100px")}
              </label>
              <label
                style={{
                  display: "block",
                  marginBottom: 10,
                }}
              >
                参加制約
                {saV.text(`seiyaku`, "", "text", "block", true, "100px")}
              </label>
            </section>
            <h3>環境因子</h3>
            <section>
              <label
                style={{
                  display: "block",
                  marginBottom: 10,
                }}
              >
                促進因子
                {saV.text(`sokushin`, "", "text", "block", true, "100px")}
              </label>
              <label
                style={{
                  display: "block",
                  marginBottom: 10,
                }}
              >
                阻害因子
                {saV.text(`sogai`, "", "text", "block", true, "100px")}
              </label>
            </section>
            <h3>個人因子</h3>
            <section>
              <label
                style={{
                  display: "block",
                  marginBottom: 10,
                }}
              >
                {saV.text(`kojin`, "", "text", "block", true, "100px")}
              </label>
            </section>
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
