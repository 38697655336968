export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public comment_1: string = "";
  public comment_10: string = "";
  public comment_11: string = "";
  public comment_12: string = "";
  public comment_13: string = "";
  public comment_14: string = "";
  public comment_15: string = "";
  public comment_16: string = "";
  public comment_17: string = "";
  public comment_18: string = "";
  public comment_19: string = "";
  public comment_2: string = "";
  public comment_3: string = "";
  public comment_4: string = "";
  public comment_5: string = "";
  public comment_6: string = "";
  public comment_7: string = "";
  public comment_8: string = "";
  public comment_9: string = "";
  public hasshoubi: string = "";
  public create_date: string = "";
  public hyoukatantou: string = "";
  public pat_age: string = "";
  public pat_birthday: string = "";
  public pat_name: string = "";
  public pat_number: string = "";
  public pat_sex: string = "";
  public idoushudan: string = "";
  public point_1: number = 1;
  public point_10: number = 1;
  public point_11: number = 1;
  public point_12: number = 1;
  public point_13: number = 1;
  public point_14: number = 1;
  public point_15: number = 1;
  public point_16: number = 1;
  public point_17: number = 1;
  public point_18: number = 1;
  public point_19: number = 1;
  public point_2: number = 1;
  public point_3: number = 1;
  public point_4: number = 1;
  public point_5: number = 1;
  public point_6: number = 1;
  public point_7: number = 1;
  public point_8: number = 1;
  public point_9: number = 1;
  public syoubyoumei: string = "";
  public total_point: number = 0;
}

export class Checks {}
