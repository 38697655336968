export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public create_date: string = "";
  public dekiru: string = "";
  public haiyou: string = "";
  public kojin: string = "";
  public name: string = "";
  public pat_name: string = "";
  public sakuseisya: string = "";
  public sanka: string = "";
  public seigen: string = "";
  public seiyaku: string = "";
  public shikkan: string = "";
  public shinshinkinou: string = "";
  public shiteiru: string = "";
  public sogai: string = "";
  public sokushin: string = "";
  public syougai: string = "";
}

export class Checks {}
