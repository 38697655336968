import axios, { AxiosRequestConfig } from "axios";
import { AuthUrl, CustomTokenhUrl, AuthenticateServer } from './api';
import { observable, autorun } from 'mobx';
import Firebase from '../firebase'
export const ACCESS_TOKEN_KEY = 'accessToken'
export const REFRESH_TOKEN_KEY = 'refreshToken'
export const CUSTOM_TOKEN_KEY = 'customToken'
export const COGNITO_JWT = 'cognitoJwt'
const OAUTH_TOKEN_URI = AuthUrl()
const CUSTOM_TOKEN_URI = CustomTokenhUrl()

const AWS= require('aws-sdk')
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const validate = (val:string|null) => !val ? null : val === "null" ? null : val
export const sessionObservable = observable({
    accessToken: validate(localStorage.getItem(ACCESS_TOKEN_KEY)),
    refreshToken: validate(localStorage.getItem(REFRESH_TOKEN_KEY)),
    customToken: validate(localStorage.getItem(CUSTOM_TOKEN_KEY)),
    cognitoJwt: validate(localStorage.getItem(COGNITO_JWT))
})

autorun(() => {
    localStorage.setItem(ACCESS_TOKEN_KEY, sessionObservable.accessToken!)
    localStorage.setItem(REFRESH_TOKEN_KEY, sessionObservable.refreshToken!)
    localStorage.setItem(CUSTOM_TOKEN_KEY, sessionObservable.customToken!)
    localStorage.setItem(COGNITO_JWT, sessionObservable.cognitoJwt!)
})

export const login = async (param:{
    username:string,
    password:string
}) => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': `application/json`
        }
    }
    return await axios.create().post(OAUTH_TOKEN_URI, {
        "grant_type": "password",
        "username": param.username,
        "password" : param.password
    }, config).then(x => x.data as OAuthTokenResponse<false>)
    .then(
        x => {
            sessionObservable.accessToken = x.access_token
            sessionObservable.refreshToken = x.refresh_token
            setCustomToken();
            return {
                accessToken:x.access_token,
                refreshToken:x.refresh_token
            }
        }
    )
}

const poolData = {
    UserPoolId: AuthenticateServer.UserPoolId,
    ClientId: AuthenticateServer.ClientId,
    Storage: sessionStorage
};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
// Amazon Cognito 認証情報プロバイダーを初期化します
AWS.config.region = AuthenticateServer.Region; // リージョン
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: AuthenticateServer.IdentityPoolId,
});

export const cognitoLogin = async(param: {
    username:string,
    password:string
}) => {
    // 認証データの作成
    const authenticationData = {
        Username: param.username,
        Password: param.password
    };
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
        authenticationData
    );

    const userData = {
        Username: param.username,
        Pool: userPool,
        Storage: sessionStorage
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    // 認証処理
    let p =  new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: result => {
                const idToken = result.getIdToken().getJwtToken(); // IDトークン
                //const accessToken = result.getAccessToken().getJwtToken(); // アクセストークン
                //const refreshToken = result.getRefreshToken().getToken(); // 更新トークン

                sessionObservable.cognitoJwt = idToken
                let r = {
                    cognitoJwt:idToken
                }
                resolve(r)
            },
            onFailure: err => {
                reject(err)
            }
        });
    });
    return await p;
}

export const logout = () => {
    sessionObservable.accessToken = null
    sessionObservable.refreshToken = null
    sessionObservable.customToken = null
    sessionObservable.cognitoJwt = null
    sessionStorage.clear();
}


export const authorizedFetch = (() => {
    const instance = axios.create()

    // REQUEST
    instance.interceptors.request.use(
        (config) => {
            // console.log(`---- ${config.method ? config.method.toUpperCase() : "method:null"} ${config.url} ----`);
            if (sessionObservable.cognitoJwt) {
                config.headers = Object.assign(config.headers,{
                    //"Authorization" : `Bearer ${sessionObservable.accessToken}`,
                    "Content-Type": "application/json",
                    "XXX-Authorize": sessionObservable.cognitoJwt
                })
            }
            return config;
        },
        error => {
            if (error && error.request) {
                console.log(error);
            }
            return Promise.reject(error);
        });

    // RESPONSE
    instance.interceptors.response.use(
        (response) => {
            if (!sessionObservable.customToken) {
                setCustomToken()
            }
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            if (!error.response) {
                if (error.response.status ===　413) {
                    alert("サイズを確認してください（<50MB)")
                    return
                }
                else {
                    alert("通信状況を確認してください")
                }
                return
            }
            if (error.response.status === 401 && sessionObservable.refreshToken) {
                console.log('begin refresh')
                const result = await refreshToken()
                if(result === "ok"){
                    return authorizedFetch(originalRequest)
                }
                sessionObservable.accessToken = null
                sessionObservable.refreshToken = null
                sessionObservable.customToken = null
                window.location.href = "/login"   
            }
            else if (error.response.status === 412) {
                console.log('need mfa')
                if (window.location.pathname.indexOf("/login") < 0 && window.location.pathname.indexOf("/password_reset")<0) {
                    window.location.href = "/login"
                }
            }
            return Promise.reject(error);
        }
    );
    return instance
})()

const refreshToken = async () => {
    const config:AxiosRequestConfig = {
        headers:{
            'Content-Type': `application/json`
        }
    }
    try {
    return await axios.create().post(OAUTH_TOKEN_URI,{
        "grant_type": "refresh_token",
        "refresh_token": sessionObservable.refreshToken
    },config)
    .then(x => x.data as OAuthTokenResponse<boolean>)
    .then(res => {
        if(res.is_refresh_revoke){
            return "ng"
        }
        sessionObservable.accessToken = res.access_token
        sessionObservable.refreshToken = res.refresh_token
        setCustomToken();
        return "ok"
    })
    }
    catch
    {
        return "ng"
    }
}

const setCustomToken = () => {
    authorizedFetch.get(CUSTOM_TOKEN_URI).then(res => {
        const token = res.data
        sessionObservable.customToken = res.data
        Firebase.instance.auth.signInWithCustomToken(token).then(res => {
        }).catch(function (err) {
            console.log(err)
        });
    }).then(err => {
        console.log(err)
    })
}

type OAuthTokenResponse<T extends boolean> = T extends true ?
    { "is_refresh_revoke": T }
    :{
        "is_refresh_revoke": T,
        "access_token": string,
        "token_type": "Bearer",
        "expires_in": number,
        "refresh_token": string,
        "created_at": number
    }
