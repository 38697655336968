import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 87;
  }

  public createContent(): any {
    const res = new FormModel.PdOut.Core();
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    let total = 0;
    const checks = Array(18)
      .fill(0)
      .map((_, i) => `check_${i + 1}`);

    checks.forEach((check, i) => {
      console.log(doc.content.checks[check]);
      console.log(i % 3);
      if (doc.content.checks[check]) {
        total += i % 3;
      }
    });

    doc.content.values.total_point = total;
  }

  public beforeMount = () => {};
  public setPatient() {}

  public buildElements(
    doc: Model.Document.Document,
    sa: StoreAccessor
  ): {
    name: string;
    content: { name: string; content: React.ReactElement }[];
  }[] {
    return this.buildElements_pdf(doc, sa);
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saC = sa.generateChild(["content", "checks"]);

    const elements_t = [
      {
        name: "内容",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              腫脹
              {saC.radio([
                {
                  label: "なし",
                  key: `check_1`,
                },
                {
                  label: "出口のみ；＜ 0.5cm",
                  key: `check_2`,
                },
                {
                  label: "＞ 0.5cmおよび/またはトンネル",
                  key: `check_3`,
                },
              ])}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              痂皮
              {saC.radio([
                {
                  label: "なし",
                  key: `check_4`,
                },
                {
                  label: "＜ 0.5cm",
                  key: `check_5`,
                },
                {
                  label: "＞ 0.5cm",
                  key: `check_6`,
                },
              ])}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              発赤
              {saC.radio([
                {
                  label: "なし",
                  key: `check_7`,
                },
                {
                  label: "＜ 0.5cm",
                  key: `check_8`,
                },
                {
                  label: "＞ 0.5cm",
                  key: `check_9`,
                },
              ])}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              疼痛
              {saC.radio([
                {
                  label: "なし",
                  key: `check_10`,
                },
                {
                  label: "軽度",
                  key: `check_11`,
                },
                {
                  label: "重度",
                  key: `check_12`,
                },
              ])}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              排膿
              {saC.radio([
                {
                  label: "なし",
                  key: `check_13`,
                },
                {
                  label: "漿液性",
                  key: `check_14`,
                },
                {
                  label: "膿性",
                  key: `check_15`,
                },
              ])}
            </label>
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
