import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import * as FormBehaviour from './FormBehaviour'
import Calendar from 'react-calendar';
import { Button, FormControlLabel, Checkbox } from '@material-ui/core';
import DateUtil from '../../util/DateUtil';
import moment from 'moment';
import * as HokanApi from '../../api/hokan'

class TempData {
    selectedDate: string | null = null
    exists: boolean = false
    kirokus: HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII> = new HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII>()
}


// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
    displayName = "Behaviour"

    public getId(): number {
        return 9
    }

    public createContent(): any {
        const res = new FormModel.Houkokusho.Core()
        const cd = DateUtil.currentDateStr()
        res.values.create_date = cd
        res.values.calendar_from = DateUtil.currentMonthFirstDateStr();
        res.values.calendar_to = DateUtil.currentMonthLastDateStr();
        res.values.calendar = this.getCalendarStr(res.values.calendar_from, res)
        res.values.calendar2 = ""
        /*
        "2019年10月\r\n" +
        "月　　火　　水　　木　　金　　土　　日\r\n" +
        " 1◎　 2　　 3　　 4　　 5　　 6　　 7\r\n" +
        " 8　　 9△　10　　11　　12　　13　　14\r\n" +
        "15○　16　　17　　18　　19　　20　　21\r\n" +
        "22　　23×　24　　25　　26　　27　　28\r\n" +
        "29　　30　　31　　  　　  　　  　　  \r\n" +
        "29　　30　　31　　  　　  　　  　　  \r\n";
        */
        return res
    }

    private getCalendarStr(date: string, content: FormModel.Houkokusho.Core) {
        let res =
            `${DateUtil.toJpMonthString(date)}\r\n` +
            "日　　月　　火　　水　　木　　金　　土\r\n"

        const momFrom = moment(date)
        const calFrom = momFrom.clone().startOf("month")
        const calTo = momFrom.clone().endOf("month")
        const mom = calFrom.clone()

        for (let w = 0; w < 6; ++w) {
            let cs = ""
            for (let i = 0; i < 7; ++i) {
                const wd = mom.weekday()
                const ds = mom.format("YYYY-MM-DD")
                let mark = '　'
                if (wd == i % 7 && mom <= calTo) {
                    const d = mom.get('date')
                    if (d < 10) cs += ' ' + d
                    else cs += d
                    mark = content.selected[ds] || '　'
                    mom.add(1, 'd')
                }
                else cs += '　'
                cs += mark
                cs += '　'
            }
            res += cs + "\r\n"
        }
        return res
    }

    public setCalendar(doc: Model.Document.Document, sa: StoreAccessor | null = null) {
        const content = doc.content as FormModel.Houkokusho.Core
        const date_from = moment(content.values.calendar_from)
        const date_to = moment(content.values.calendar_to)

        let month_diff = date_to.get("month") - date_from.get("month")
        if (date_to.get("year") > date_from.get("year")) {
            month_diff = (month_diff + 12) % 12
        }
        content.values.calendar = this.getCalendarStr(content.values.calendar_from, content)
        if (month_diff == 1) {
            content.values.calendar2 = this.getCalendarStr(content.values.calendar_to, content)
        }
        else {
            content.values.calendar2 = ""
        }
        if (sa) sa.updateState()
    }

    public setCreateDate(doc: Model.Document.Document, create_date: string, sa: StoreAccessor) {
        const c = doc.content as FormModel.Houkokusho.Core

        doc.target_month = DateUtil.toTargetMonthNum(create_date)
        if (!c.dateInitialized && !doc.id && create_date) {
            c.dateInitialized = true;
            c.values.calendar_from = moment(c.values.create_date).startOf("month").format("YYYY-MM-DD")
            c.values.calendar_to = moment(c.values.calendar_from).endOf("month").format("YYYY-MM-DD")
            c.values.create_date = c.values.calendar_to
        }
        else {
            c.values.create_date = create_date;
        }
        this.existsCheck(doc, sa, doc.patient, doc.target_month)
    }
    private existsCheck(doc: Model.Document.Document, sa: StoreAccessor, pat: Model.Patient.Patient | null, target_month: number | null) {
        if (!pat) {
            return;
        }
        HokanApi.checkDocumentExists(pat.id, doc.document_type.id, target_month, doc.id).then(res => {
            doc.temp_data.exists = res.data
            doc.cannotCommit = res.data
            sa.updateState()
        })
        sa.updateState()
    }


    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient, sa: StoreAccessor) {
        const c = doc.content as FormModel.Houkokusho.Core
        doc.patient = pat;
        if (pat) {
            this.loadFromPatientBasic(doc, sa)
            this.setCreateDate(doc, c.values.create_date, sa)
        }
    }

    public loadFromPatientBasic(doc: Model.Document.Document, sa: StoreAccessor) {
        const c = doc.content as FormModel.Houkokusho.Core
        const pat = doc.patient
        if (pat) {
            c.values.pat_youkaigo = pat.youkaigo;
            c.values.pat_birthday = pat.birthday;
            if (pat.hoken) {
                if (pat.hoken.hoken_type == Model.Patient.HokenType.Seishin) {
                    c.values.title = Model.Forms.Houkokusho.HoukokuType.SeishinHoukan
                }
                else if (pat.hoken.hoken_type == Model.Patient.HokenType.Kaigo
                    || pat.hoken.hoken_type == Model.Patient.HokenType.Iryo) {
                    c.values.title = Model.Forms.Houkokusho.HoukokuType.Houkan
                }
                else if (pat.hoken.hoken_type == Model.Patient.HokenType.KaigoYobo) {
                    c.values.title = Model.Forms.Houkokusho.HoukokuType.KaigoYobouHoukan
                }
            }
            c.values.pat_address = pat.env.address1 + pat.env.address2 + pat.env.address3;
            sa.updateState();
        }
    }

    private loadKirokuIIs(doc: Model.Document.Document, from: string, to: string, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        HokanApi.getKirokuIIsForHoukoku(doc.patient.id, from, to).then(x => {
            const d = x.data
            d.data = d.data.map(y => Model.KirokuII.KangoKirokuII.load(y))
            d.data.sort((a, b) => {
                if (a.time_start > b.time_start) return -1
                if (b.time_start > a.time_start) return +1
                return 0
            })
            doc.temp_data.kirokus = d;
            let minMax = {
                minTaion: 0,
                maxTaion: 0,
                minMyaku: 0,
                maxMyaku: 0,
                minKetsuatsuMin: 0,
                maxKetsuatsuMin: 0,
                minKetsuatsuMax: 0,
                maxKetsuatsuMax: 0,
                minSpo2: 0,
                maxSpo2: 0,
                minKettou: 0,
                maxKettou: 0,
                minTaiju: 0,
                maxTaiju: 0
            }

            const c = doc.content as FormModel.Houkokusho.Core
            c.selected = {}
            d.data.forEach(kiroku => {
                const mom = moment(kiroku.time_start)
                const s = mom.format("YYYY-MM-DD")
                c.selected[s] = null
            })
            const tokushiji = (d as any).tokushiji
            const tokuDays:any[] = []

            if (tokushiji && tokushiji.length > 0) {
                tokushiji.forEach( (t) => {
                    if (t.content) {
                        const content = JSON.parse(t.content)
                        const tsj = {}
                        if (content.date_from) {
                            const tmomfrom = moment(content.date_from).format("YYYY-MM-DD")
                            tsj["from"] = tmomfrom
                        }
                        else tsj["from"] = false
                        if (content.date_to) {
                            const tmomto = moment(content.date_to).format("YYYY-MM-DD")
                            tsj["to"] = tmomto
                        } 
                        else tsj["to"] = false
                        tokuDays.push(tsj)
                    }
                })
            }

            console.log(tokuDays)

            console.log(d.data)
            d.data.forEach(kiroku => {
                if (kiroku.kiroku_type == Model.KirokuII.KirokuType.Jihi) return;
                const mom = moment(kiroku.time_start)
                const momTo = moment(kiroku.time_end)
                const diffmin = (mom && momTo) ? momTo.diff(mom, "minutes") : 60
                const s = mom.format("YYYY-MM-DD")
                const t = c.selected[s]
                let toku: boolean = false
                const moms = mom.format("YYYY-MM-DD")
                tokuDays.forEach((td) => {
                    console.log(td["from"], td["to"], moms, td["from"] <= moms, td["to"] == false, moms <= td["to"])
                    if ((td["from"] && td["from"] <= moms) &&
                        (td["to"] == false || moms <= td["to"])) {
                            toku = true
                        }
                })
                console.log(kiroku.time_start)
                console.log(t)

                const setMinMax = (src, minName, maxName) => {
                    if (!kiroku.vital) return
                    const t = src == "taiju" ?
                        kiroku.vital.taiju :
                        ((kiroku.vital.saiken && kiroku.vital.saiken[src]) ? kiroku.vital.saiken[src] : kiroku.vital.first[src])
                    if (!t) return;
                    const tn = parseFloat(t)
                    if (tn) {
                        minMax[minName] = minMax[minName] ? Math.min(minMax[minName], tn) : tn
                        minMax[maxName] = minMax[maxName] ? Math.max(minMax[maxName], tn) : tn
                    }
                }

                setMinMax("taion", "minTaion", "maxTaion")
                setMinMax("myakuhaku", "minMyaku", "maxMyaku")
                setMinMax("ketsuatsu_min", "minKetsuatsuMin", "maxKetsuatsuMin")
                setMinMax("ketsuatsu_max", "minKetsuatsuMax", "maxKetsuatsuMax")
                setMinMax("spo2", "minSpo2", "maxSpo2")
                setMinMax("taiju", "minTaiju", "maxTaiju")
                setMinMax("kettou_value", "minKettou", "maxKettou")

                let res = "○" // ○：訪問日
                if (t != null) res = "◎" // ◎：同日複数回訪問
                else if (kiroku.basic.is_kinkyu) res = "×" // 緊急
                else if (toku) res = "△" // △：特別訪問看護指示
                else if (
                    kiroku.basic.kasan.is_chojikan_houmon_kango ||
                    kiroku.basic.kasan.basic.is_choujikan ||
                    kiroku.basic.kasan.seishin.is_choujikan
                ) res = "□" //□：長時間訪問加算
                else if (kiroku.kiroku_type == Model.KirokuII.KirokuType.SeishinHoukan && diffmin < 30) res = "レ" // "×" //×：精神30分未満
                else if (kiroku.basic.shikaku && kiroku.basic.shikaku.toString() == "2") res = "◇" // ◇：PT,OT,STによる訪問

                c.selected[s] = res
            })

            const toS = (minv, maxv, unit) => {
                return `${minv == 0 ? "-" : minv}〜${maxv == 0 ? "-" : maxv}${unit}`
            }
            c.values.taion = toS(minMax.minTaion, minMax.maxTaion, "℃")
            c.values.myakuhaku = toS(minMax.minMyaku, minMax.maxMyaku, "回/分")
            c.values.ketsuatsu = toS(minMax.minKetsuatsuMax, minMax.maxKetsuatsuMax, "") + "/" +
                toS(minMax.minKetsuatsuMin, minMax.maxKetsuatsuMin, "mmHg")
            c.values.spo2 = toS(minMax.minSpo2, minMax.maxSpo2, "%")
            c.values.taiju = toS(minMax.minTaiju, minMax.maxTaiju, "")
            c.values.kettou = toS(minMax.minKettou, minMax.maxKettou, "")
            sa.updateState();
        })
    }

    private loadLatest(doc: Model.Document.Document, sa: StoreAccessor, value_names: string[], check_names: string[]) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const current = doc.content as FormModel.Houkokusho.Core
        HokanApi.getDocumentLatest(doc.patient.id, doc.document_type.id, current.values.create_date ? current.values.create_date : undefined).then(res => {
            const latest = res.data.content as FormModel.Houkokusho.Core
            value_names.forEach(name => current.values[name] = latest.values[name])
            check_names.forEach(name => current.checks[name] = latest.checks[name])
            sa.updateState()
        }).catch(err => {
            console.log(err)
        })
    }


    private loadFromLatestDoc(doc: Model.Document.Document, sa: StoreAccessor, value_name: string[], docId: number, doc_value_name: string[]) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const current = doc.content as FormModel.Houkokusho.Core
        let dt = current.values.create_date ? current.values.create_date : undefined
        if (dt) {
            dt = moment(current.values.create_date).add('days', 1).format("YYYY-MM-DD");
        }
        HokanApi.getDocumentLatest(doc.patient.id, docId, dt).then(res => {
            const latest = res.data.content as FormModel.Houkokusho.Core
            for (var i = 0; i < value_name.length; ++i) {
                current.values[value_name[i]] = latest.values[doc_value_name[i]]
            }
            sa.updateState()
        }).catch(err => {
            console.log(err)
        })
    }


    public beforePost_pdf(doc: Model.Document.Document) {
        const content = doc.content as FormModel.Houkokusho.Core;
        this.setCalendar(doc)
    }

    public beforeMount(doc: Model.Document.Document, sa: StoreAccessor) {
        doc.temp_data = new TempData()
        const content = doc.content as FormModel.Houkokusho.Core
        if (!content.selected) content.selected = {}
        this.existsCheck(doc, sa, doc.patient, doc.target_month)
    }

    private loadFromBranch(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const content = doc.content as FormModel.Houkokusho.Core
        const values = content.values
        HokanApi.branch.get(doc.patient.branch_id).then(res => {
            values.branch_name = res.data.name
            values.kanri_name = res.data.kanri_name
            sa.updateState()
        }).catch(err => { console.log(err) })
        HokanApi.user.get(doc.patient.main_user_id).then(res => {
            values.tanto1_name = res.data.name
            values.tanto1_role = Model.User.getShikakuName(res.data.shikaku)
            sa.updateState()
        }).catch(err => { console.log(err) })
        HokanApi.user.get(doc.patient.sub_user1_id).then(res => {
            values.tanto2_name = res.data.name
            values.tanto2_role = Model.User.getShikakuName(res.data.shikaku)
            sa.updateState()
        }).catch(err => { console.log(err) })
    }

    // 病状の経過：問題ごとに、直近記録IIのSOAPが入力されていればそこから取る,
    private loadFromKirokuII(doc: Model.Document.Document, to: string, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const t = moment(to).add(1, "day")
        HokanApi.getKirokuIILatestN(doc.patient.id, 1, t.format("YYYY-MM-DD")).then(async _lk => {
            if (_lk.data.length > 0) {
                let lk = _lk.data.map(y => Model.KirokuII.KangoKirokuII.load(y))[0]
                const content = doc.content as FormModel.Houkokusho.Core
                content.values.keika = lk.deal.assessment;
                sa.updateState();
            }
        })
    }

    // 家庭での・・：記録Iの、家族などの介護 / 養育の状況や危機.
    private loadFromPatientKazoku(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const content = doc.content as FormModel.Houkokusho.Core
        if (doc.patient.social) {
            content.values.katei_kaigo = doc.patient.social.kiki_family
            sa.updateState();
        }
    }

    // 看護リハ内容：問題ごとに、オマハの介入にチェックが入っているものを出す
    private async loadFromAssessmentNaiyou(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        HokanApi.getPatientAssessments(doc.patient.id).then(async _assList => {
            if (doc.patient == null) return;
            const content = doc.content as FormModel.Houkokusho.Core
            const values = content.values
            const x = _assList.data
            if (x != null) {
                values.naiyou = ""
                x.sort((a, b) => {
                    if (a.oldest.assessment.assessment_day < b.oldest.assessment.assessment_day) return -1;
                    if (a.oldest.assessment.assessment_day > b.oldest.assessment.assessment_day) return +1;
                    return 0;
                }).forEach(y => {
                    if (y.newest.assessment.priority == 1 && y.newest.assessment.closed == false) {
                        let t = `#${y.problem.name}：`
                        y.newest.intervention.map(intv => {
                            const w = y.newest.assessment_intervention.find(ai => ai.intervention_id == intv.id)
                            const cm = w && w.comment && w.comment != "" ? w.comment : ""
                            t += (intv.target == "その他" ? (cm == "" ? "その他" : "") : intv.target) + cm + "/"
                        })
                        t += "\n"
                        values.naiyou += t
                    }
                })
            }
            sa.updateState()
        }).catch(err => {
            console.log(err)
        })
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor, classes: any) {
        const content = doc.content as FormModel.Houkokusho.Core
        if (!content.selected) content.selected = {}
        const saV = sa.generateChild(["content", "values"])
        const saC = sa.generateChild(["content", "checks"])
        const copyButton = (value_names: string[], check_names: string[]) => <Button variant="outlined" size="small"
            onClick={(e) => this.loadLatest(doc, sa, value_names, check_names)}
            className={classes.loadButton} >前回から取得</Button>

        const noHoukokuSaki = (!content.values.houkoku_saki || content.values.houkoku_saki.length == 0)
        const cannotCommit = noHoukokuSaki || doc.temp_data.exists
        if (cannotCommit != doc.cannotCommit) {
            doc.cannotCommit = cannotCommit
            sa.updateState()
        }

        const elements_basic = [
            {
                name: "コピー", content:
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromPatientBasic(doc, sa)}
                        className={classes.loadButton} >記録Iから取得</Button>
            },
            { name: "タイトル", content: saV.list("title", Model.Forms.Houkokusho.HoukokuType) },
            { name: "生年月日", content: saV.date("pat_birthday") },
            { name: "要介護認定の状況", content: saV.list("pat_youkaigo", Model.Patient.YouKaigoType) },
            { name: "住所", content: saV.text("pat_address") },
            { name: "年月", content: <div>{DateUtil.toTargetMonthStr(content.values.create_date)}{doc.temp_data.exists ? <div style={{ color: "red", fontWeight: "bold" }}>エラー：他に存在します</div> : ""}</div> }
        ]

        const houkoku_saki = saV.getValue("houkoku_saki")

        if (houkoku_saki) {
            if (houkoku_saki.findIndex(x => x == null) >= 0) {
                const p = houkoku_saki.filter(x => x != null)
                saV.setValue("houkoku_saki", p)
            }
        }

        const patId = doc.patient ? doc.patient.id : undefined
        const elements_houkoku = [
            { name: "コピー", content: copyButton(["houkoku_saki"], []) },
            {
                name: "内容", content: <div>
                    {
                        noHoukokuSaki ?
                            <div style={{ color: "red", fontWeight: "bold" }}>報告先は一つ以上必要です</div>
                            : null
                    }
                    {
                        houkoku_saki.map((o, i) => {
                            return saV.orgTantoC(["houkoku_saki"], i, patId)
                        })
                    }
                    {
                        sa.isEditing ?
                            <Button variant="outlined" size="small" color="primary"
                                onClick={(e) => {
                                    houkoku_saki.push(new Model.OrganizationTanto());
                                    saV.setValue("houkoku_saki", houkoku_saki)
                                }}>
                                ＋追加
                            </Button>
                            : null
                    }
                </div>
            },
        ]
        const marks = [null, "○", "△", "◎", "□", "レ", "◇", "×"]
        const date_from = moment(content.values.calendar_from)
        const date_from_end = moment(content.values.calendar_from).endOf("month")
        const date_to = moment(content.values.calendar_to)
        const date_to_start = moment(content.values.calendar_to).startOf("month")

        let month_diff = date_to.get("month") - date_from.get("month")
        let year_diff = date_to.get("year") - date_from.get("year")

        let isValid = false
        let isTwoMonth = false
        if (date_from >= date_to) isValid = false
        else if (year_diff == 0) {
            isValid = (month_diff <= 1)
            if (month_diff == 1) isTwoMonth = true
        }
        else if (year_diff == 1) {
            if (date_from.get("month") == 11 && date_to.get("month") == 0) {
                isValid = true
                isTwoMonth = true
            }
        }

        const elements_houmon = [
            {
                name: "報告対象期間", content: <div><div>
                    {saV.date("calendar_from")}〜
                    {saV.date("calendar_to")}
                </div>
                    {
                        isValid ? null : <div style={{ color: "red", fontWeight: "bold" }}>エラー：日付を確認してください</div>
                    }
                </div>
            },
            {
                name: "記録から取得", content: <div><Button variant="outlined" size="small"
                    onClick={(e) => this.loadKirokuIIs(doc, content.values.calendar_from, content.values.calendar_to, sa)}
                    className={classes.loadButton} >取得</Button>
                    (バイタルにも反映されます)
                </div>
            },
            {
                name: "内容", content: <div>
                    <div>
                        <Calendar
                            locale="ja"
                            calendarType="US"
                            activeStartDate={date_from.toDate()}
                            minDate={date_from.toDate()}
                            maxDate={month_diff == 1 ? date_from_end.toDate() : date_to.toDate()}
                            nextLabel={''}
                            prevLabel={''}
                            className={classes.calendar_houkokusho}
                            tileContent={({ date, view }) => {
                                const ds = moment(date).format("YYYY-MM-DD")
                                return <p>{content.selected[ds]}</p>
                            }}
                            onClickDay={(p) => {
                                doc.temp_data.selectedDate = moment(p).format("YYYY-MM-DD")
                                sa.updateState()
                            }}
                            onChange={(date) => {
                            }}
                        />
                        {
                            isTwoMonth == true ?
                                <Calendar
                                    locale="ja"
                                    calendarType="US"
                                    activeStartDate={date_to_start.toDate()}
                                    minDate={date_to_start.toDate()}
                                    maxDate={date_to.toDate()}
                                    nextLabel={''}
                                    prevLabel={''}
                                    className={classes.calendar_houkokusho}
                                    tileContent={({ date, view }) => {
                                        const ds = moment(date).format("YYYY-MM-DD")
                                        return <p>{content.selected[ds]}</p>
                                    }}
                                    onClickDay={(p) => {
                                        const ds = moment(p).format("YYYY-MM-DD")
                                        doc.temp_data.selectedDate = ds
                                        sa.updateState()
                                    }}
                                    onChange={(date) => {
                                    }}
                                />
                                : null
                        }
                    </div>
                    <div>○：訪問日　△：特別訪問看護指示　◎：同日複数回訪問　□：長時間訪問加算　レ：精神30分未満　◇：PT,OT,STによる訪問　×：緊急訪問</div>
                    <div>
                        {doc.temp_data.selectedDate}：
                        {
                            doc.temp_data.selectedDate ?
                                marks.map(mark => {
                                    return <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={content.selected[doc.temp_data.selectedDate] == mark}
                                                onChange={(e) => {
                                                    content.selected[doc.temp_data.selectedDate] = mark
                                                    this.setCalendar(doc, sa)
                                                }}
                                                color="primary"
                                            />
                                        }
                                        key={mark || ""}
                                        label={mark}
                                    />
                                })
                                : null
                        }
                    </div>
                </div>
            },
        ]

        const elements_keika = [
            {
                name: "バイタル", content: <div>
                    体温：{saV.text("taion", "", "text", "inline-flex")}<br />
                    脈拍：{saV.text("myakuhaku", "", "text", "inline-flex")}<br />
                    血圧：{saV.text("ketsuatsu", "", "text", "inline-flex")}<br />
                    SpO2：{saV.text("spo2", "", "text", "inline-flex")}<br />
                    血糖値 {saV.text("kettou", "", "text", "inline-flex")}<br />
                    体重： {saV.text("taiju", "", "text", "inline-flex")}</div >
            },
            {
                name: "コピー", content: <div>
                    {copyButton(["keika"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromKirokuII(doc, content.values.calendar_to, sa)}
                        className={classes.loadButton} >記録IIから取得</Button>
                </div>
            },
            { name: "記述", content: saV.text("keika") },
        ]

        const elements_riha = [
            {
                name: "コピー", content: <div>
                    {copyButton(["naiyou"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromAssessmentNaiyou(doc, sa)}
                        className={classes.loadButton} >オマハから取得</Button>
                </div>
            },
            { name: "内容", content: saV.text("naiyou") },
        ]
        const elements_katei = [
            {
                name: "コピー", content: <div>
                    {copyButton(["katei_kaigo"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromPatientKazoku(doc, sa)}
                        className={classes.loadButton} >記録Iから取得</Button>
                </div>
            },
            { name: "内容", content: saV.text("katei_kaigo") },
        ]
        const elements_eisei_jokyo = [
            { name: "コピー", content: copyButton(["eisei_name", "eisei_siyouryou", "eisei_hindo"], []) },
            { name: "名称", content: saV.text("eisei_name") },
            { name: "使用量", content: saV.text("eisei_siyouryou") },
            { name: "交換頻度", content: saV.text("eisei_hindo") },
        ]

        const checkAri = doc.content.checks.check_ari
        const elements_eisei_henko = [
            { name: "コピー", content: copyButton(["henko_naiyo"], ["check_nashi", "check_ari"]) },
            {
                name: "変更の必要性", content: saC.checkList([
                    { label: "なし", key: "check_nashi" },
                    { label: "あり", key: "check_ari" },
                ], false, true)
            },
            {
                name: "変更内容", content:
                    checkAri ?
                        saV.text("henko_naiyo")
                        : <div>-</div>
            },
        ]

        const elements_teikyo = [
            { name: "コピー", content: copyButton(["jouho_teikyo_saki"], []) },
            { name: "訪問看護情報提供療養費に係る情報提供先", content: saV.text("jouho_teikyo_saki") },
            { name: "情報提供日", content: saV.date("jouho_teikyo_date") }
        ]
        const elements_comment = [
            { name: "コピー", content: copyButton(["comment"], []) },
            { name: "内容", content: saV.text("comment") },
        ]

        const elements_gaf = [
            {
                name: "コピー", content: <div>
                    {copyButton(["gaf_score"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromLatestDoc(doc, sa, ["gaf_score", "gaf_date"], 55, ["score", "create_date"])}
                        className={classes.loadButton}>書類から取得</Button>
                </div>
            },
            {
                name: "スコア", content: saV.text("gaf_score")
            },
            {
                name: "日付", content: saV.date("gaf_date")
            },
        ]
        const elements_houkoku_moto = [
            {
                name: "コピー", content: <div>
                    {copyButton(["branch_name", "kanri_name", "tanto1_name", "tanto2_name"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromBranch(doc, sa)}
                        className={classes.loadButton} >事業所情報から取得</Button>
                </div>
            },
            { name: "事業所名", content: saV.text("branch_name") },
            { name: "管理者名", content: saV.text("kanri_name") },
            {
                name: "担当者①", content: <div>
                    {saV.text("tanto1_name", "名前")}
                    {saV.text("tanto1_role", "職種")}
                </div>
            },
            {
                name: "担当者②", content: <div>
                    {saV.text("tanto2_name", "名前")}
                    {saV.text("tanto2_role", "職種")}
                </div>
            }
        ]

        const elements = [
            { name: '基本', content: elements_basic },
            { name: '報告先', content: elements_houkoku },
            { name: '訪問日', content: elements_houmon },
            { name: '病状の経過', content: elements_keika },
            { name: '看護・リハビリテーションの内容', content: elements_riha },
            { name: content.values.title == Model.Forms.Houkokusho.HoukokuType.SeishinHoukan ? '家族等との関係' : '家庭での介護状況', content: elements_katei },
            { name: '衛生材料の使用量および使用状況', content: elements_eisei_jokyo },
            { name: '衛生材料等の種類・量の変更', content: elements_eisei_henko },
            { name: '情報提供', content: elements_teikyo },
            { name: '特記すべき事項', content: elements_comment },
            { name: 'GAF', content: elements_gaf },
            { name: '報告元', content: elements_houkoku_moto },
        ]

        return elements
    }
}
