export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public color_d: string = "";
  public damage_a: string = "";
  public damage_b: string = "";
  public damage_c: string = "";
  public damage_total: string = "";
  public create_date: string = "";
  public hyoukasya: string = "";
  public kanja_id: string = "";
  public kanja_name: string = "";
  public pat_name: string = "";
  public stoma_score_a: string = "";
  public stoma_score_b: string = "";
  public stoma_score_c: string = "";
  public stoma_score_d: string = "";
  public stoma_score_total: string = "";
}

export class Checks {
  public check_1: boolean = false;
  public check_2: boolean = false;
  public check_3: boolean = false;
}
