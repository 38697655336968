export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public total_point: string = "";
}

export class Checks {
  public check_1: boolean = true;
  public check_10: boolean = true;
  public check_11: boolean = false;
  public check_12: boolean = false;
  public check_13: boolean = true;
  public check_14: boolean = false;
  public check_15: boolean = false;
  public check_2: boolean = false;
  public check_3: boolean = false;
  public check_4: boolean = true;
  public check_5: boolean = false;
  public check_6: boolean = false;
  public check_7: boolean = true;
  public check_8: boolean = false;
  public check_9: boolean = false;
}
