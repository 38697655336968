import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import * as FormBehaviour from './FormBehaviour'
import { Button } from '@material-ui/core';
import DateUtil from '../../util/DateUtil';
import * as HokanApi from '../../api/hokan'
import Col2Table from '../../components/Col2Table'

class TempData {
    assessments: Model.Assessment.AssessmentListResult[] = []
    exists: boolean = false
}

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
    displayName = "Behaviour"
    constructor() {
        super()
    }

    public getId(): number {
        return 54
    }

    public createContent(): any {
        const res = new FormModel.KangoSummary.Core()
        const cd = DateUtil.currentDateStr()
        res.values.create_date = cd
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient, sa: StoreAccessor) {
        doc.patient = pat;
        const c = doc.content as FormModel.KangoSummary.Core
        if (pat) {
            this.loadFromPatientBasic(doc, sa)
            this.loadFromBranch(doc, sa)
            this.setCreateDate(doc, c.values.create_date, sa)
        }
    }

    private loadFromAssessment(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        HokanApi.getPatientAssessments(doc.patient.id).then(_assList => {
            if (doc.patient == null) return;
            const content = doc.content as FormModel.KangoSummary.Core
            const values = content.values
            const x = _assList.data
            if (x != null) {
                values.kango_mondai = ""
                x.sort((a, b) => {
                    if (a.oldest.assessment.assessment_day < b.oldest.assessment.assessment_day) return -1;
                    if (a.oldest.assessment.assessment_day > b.oldest.assessment.assessment_day) return +1;
                    return 0;
                }).forEach(x => {
                    if (x.newest.assessment.priority == 1 && x.newest.assessment.closed == false) {
                        values.kango_mondai += "# " + x.problem.name + " / "
                            + "知識=" + x.newest.assessment.kbs_score_k + ":" + Model.Assessment.Assessment.getKBSSentence(x.newest.assessment, "K", x.newest.assessment.kbs_score_k) + " / "
                            + "行動=" + x.newest.assessment.kbs_score_b + ":" + Model.Assessment.Assessment.getKBSSentence(x.newest.assessment, "B", x.newest.assessment.kbs_score_b) + " / "
                            + "状態=" + x.newest.assessment.kbs_score_s + ":" + Model.Assessment.Assessment.getKBSSentence(x.newest.assessment, "S", x.newest.assessment.kbs_score_s) + "\n"
                    }
                })
                values.kango_mondai = values.kango_mondai.trim()
            }
            sa.updateState()
        }).catch(err => {
            console.log(err)
        })
    }

    public loadFromPatientBasic(doc: Model.Document.Document, sa: StoreAccessor) {
        const c = doc.content as FormModel.KangoSummary.Core
        const pat = doc.patient
        this.loadFromAssessment(doc, sa)
        try {
            HokanApi.me().then(res => {
                c.values.create_user = res.data.name
            })
            if (pat && pat.id) {
                HokanApi.getPatient(pat.id).then(async res => {
                    const checkStr = (pre: string, str: string | null, post: string = "") => {
                        if (str && str != "") return pre + str + post
                        return ""
                    }
                    const p = await Model.Patient.Patient.load(res.data, true, (pp) => {
                        if (pp.doc_temp) {
                            c.values.house_tokucho = (pp.doc_temp.doc_img_tokucho ? pp.doc_temp.doc_img_tokucho.comment : "");
                            sa.updateState()
                        }
                    })
                    c.values.pat_name = p.name
                    c.values.pat_youkaigo = p.youkaigo
                    c.values.pat_birthday = p.birthday
                    if (p.hoken) {
                        c.values.pat_kouhi = (p.hoken.doc_kouhi_id ? "有り" : "無し")
                    }

                    if (p.value) {
                        if (p.value.sexuality == "Female" || p.value.sexuality.indexOf("女") >= 0) {
                            c.values.pat_sex = "女性"
                        }
                        else if (p.value.sexuality == "Male" || p.value.sexuality.indexOf("男") >= 0) {
                            c.values.pat_sex = "男性"
                        }
                    }
                    if (p.env) {
                        c.values.pat_address = (p.env.address1 || "") + (p.env.address2 || "") + (p.env.address3 || "");
                    }
                    if (p.medical) {
                        c.values.main_disease = p.medical.sick_name
                        c.values.kiou = p.medical.kiou_reki
                        c.values.yami_kiseki = p.medical.ourai_reki
                        c.values.naihuku = p.medical.yakuzai_kanri // ?
                        c.values.hihu = p.medical.hihu
                        c.values.kokyu = p.medical.kokyu
                        c.values.junkan = p.medical.junkan
                        c.values.shinkei = p.medical.physical_kokkaku
                        c.values.suimin = p.medical.physical_suimin
                        c.values.shikaku = p.medical.ishisotsu_shikaku_check ? p.medical.ishisotsu_shikaku : "良"
                        c.values.choukaku = p.medical.ishisotsu_choukaku_check ? p.medical.ishisotsu_choukaku : "良"
                        c.values.kaiwa = p.medical.ishisotsu_kaiwa_check ? p.medical.ishisotsu_kaiwa : "良"
                        c.values.ninchi = p.medical.ishisotsu_ninchi_check ? p.medical.ishisotsu_ninchi : "良"
                        c.values.ishiki = p.medical.ishisotsu_ishiki_check ? p.medical.ishisotsu_ishiki : "良"
                        c.values.drug = p.medical.yakubutsu_shikouhin

                        c.values.arergy = Model.Patient.PatientMedicalArergy.toStr(p.medical.arergy_checks) + (p.medical.arergy || "")
                        c.values.kansen = Model.Patient.PatientMedicalKansen.toStr(p.medical.kansen_checks) + (p.medical.kansen || "")
                        c.values.yobou = Model.Patient.PatientMedicalYobouSesshu.toStr(p.medical.yobousesshu_checks) + (p.medical.yobousesshu || "")

                        c.values.adl = ""
                        if (p.medical.adl) {
                            c.values.adl += "　　移乗：" + p.medical.adl.ijou + " " + (p.medical.adl.ijou_comment || "") + "\n"
                            c.values.adl += "　　移動：" + p.medical.adl.idou + " " + (p.medical.adl.idou_comment || "") + "\n"
                            c.values.adl += "階段昇降：" + p.medical.adl.kaidan + " " + (p.medical.adl.kaidan_comment || "") + "\n"
                            c.values.adl += "　　食事：" + p.medical.adl.shokuji + " " + (p.medical.adl.shokuji_comment || "") + "\n"
                            c.values.adl += "　　入浴：" + p.medical.adl.nyuyoku + " " + (p.medical.adl.nyuyoku_comment || "") + "\n"
                            c.values.adl += "　トイレ：" + p.medical.adl.toilet + " " + (p.medical.adl.toilet_comment || "") + "\n"
                            c.values.adl += "　　排尿：" + p.medical.adl.hainyou + " " + (p.medical.adl.hainyou_comment || "") + "\n"
                            c.values.adl += "　　排便：" + p.medical.adl.haiben + " " + (p.medical.adl.haiben_comment || "") + "\n"
                            c.values.adl += "　　更衣：" + p.medical.adl.koui + " " + (p.medical.adl.koui_comment || "") + "\n"
                            c.values.adl += "　　整容：" + p.medical.adl.seiyou + " " + (p.medical.adl.seiyou_comment || "")
                        }
                        c.values.iadl = ""
                        if (p.medical.iadl) {
                            c.values.iadl += "　　電話の使用：" + p.medical.iadl.denwa + " " + (p.medical.iadl.denwa_comment || "") + "\n"
                            c.values.iadl += "　　　家計管理：" + p.medical.iadl.kakei + " " + (p.medical.iadl.kakei_comment || "") + "\n"
                            c.values.iadl += "　　　　買い物：" + p.medical.iadl.kaimono + " " + (p.medical.iadl.kaimono_comment || "") + "\n"
                            c.values.iadl += "　乗り物の利用：" + p.medical.iadl.norimono + " " + (p.medical.iadl.norimono_comment || "") + "\n"
                            c.values.iadl += "　　　服薬管理：" + p.medical.iadl.hukuyak + " " + (p.medical.iadl.hukuyak_comment || "") + "\n"
                            c.values.iadl += "　　食事の準備：" + p.medical.iadl.shokuji + " " + (p.medical.iadl.shokuji_comment || "") + "\n"
                            c.values.iadl += "掃除などの家事：" + p.medical.iadl.souji + " " + (p.medical.iadl.souji_comment || "") + "\n"
                            c.values.iadl += "　　　　　洗濯：" + p.medical.iadl.sentaku + " " + (p.medical.iadl.sentaku_comment || "")
                        }
                    }
                    if (p.social) {
                        c.values.seiiku = p.social.seiiku
                        c.values.kibou_honnin = p.social.kibou_own
                        c.values.kibou_kazoku = p.social.kibou_famiry
                        c.values.kazoku = ""
                        if (p.social.social_calendar) {
                            c.values.social_monday = p.social.social_calendar.monday
                            c.values.social_tuesday = p.social.social_calendar.tuesday
                            c.values.social_wednesday = p.social.social_calendar.wednesday
                            c.values.social_thursday = p.social.social_calendar.thursday
                            c.values.social_friday = p.social.social_calendar.friday
                            c.values.social_saturday = p.social.social_calendar.saturday
                            c.values.social_sunday = p.social.social_calendar.sunday
                            c.values.social_coment = p.social.social_calendar.comment
                        }

                        if (p.social.famiry) {
                            p.social.famiry.forEach(f => {
                                c.values.kazoku += `${f.relation}:${f.name}` + 
                                checkStr(" / TEL:", f.tel, "") +
                                checkStr(" / Mail:", f.mail, "") +
                                checkStr(" / 連絡できる日:", f.good_day, "") + "\n"
                            })
                        }
                        c.values.kazoku += checkStr("", p.social.doukyo, "\n")
                    }
                    if (p.contact) {
                        const contactToStr = (tanto: Model.OrganizationTanto | null) => {
                            if (!tanto) return ""
                            return (`${tanto.org.name.padStart(4, '　')}：${tanto.name}` + checkStr("メール:", tanto.email, "") +
                                checkStr(" / ",
                                    checkStr("代表TEL:", tanto.org.tel, " / ") + checkStr("代表FAX:", tanto.org.fax, " / ") +
                                    checkStr("TEL:", tanto.tel, " / ") + checkStr("FAX:", tanto.fax, ""), "") +
                                checkStr(" / ",
                                    checkStr("緊急TEL1:", tanto.kinkyu_tel1) + checkStr("(", tanto.kinkyu_tel1_comment, ") / ") +
                                    checkStr("緊急TEL2:", tanto.kinkyu_tel2) + checkStr("(", tanto.kinkyu_tel2_comment, ")"), "\n")).trim()
                        }
                        if (p.contact.shujii) {
                            c.values.shujii = contactToStr(p.contact.shujii)
                        }
                        c.values.kankeisha = ""
                        if (p.contact.shujii_2) c.values.kankeisha += "\n医師２：" + contactToStr(p.contact.shujii_2)
                        if (p.contact.keamane) c.values.kankeisha += "\nケアマネ：" + contactToStr(p.contact.keamane)
                        if (p.contact.soudan) c.values.kankeisha += "\n計画相談員：" + contactToStr(p.contact.soudan)
                        if (p.contact.other) {
                            p.contact.other.forEach(o => {
                                c.values.kankeisha += "\nその他：" + contactToStr(o)
                            })
                        }
                        c.values.kankeisha = c.values.kankeisha.trim()
                    }

                    if (p.saigai && p.saigai.kigus) {
                        c.values.iryo_kiki = ""
                        const names = Model.Patient.IryoKiguNames
                        Object.keys(names).forEach(key => {
                            if (p.saigai.kigus[key]) {
                                if (c.values.iryo_kiki != "") c.values.iryo_kiki += " / "
                                c.values.iryo_kiki += names[key]
                            }
                        })
                    }
                    sa.updateState();
                }).catch(err => {
                    console.log(err)
                })
            }
        } catch (err) {
            console.log(err)
        }
    }


    public setCreateDate(doc: Model.Document.Document, create_date: string, sa: StoreAccessor) {
        const c = doc.content as FormModel.KangoSummary.Core
        c.values.create_date = create_date
    }

    public beforePost_pdf(doc: Model.Document.Document) {
    }

    public beforeMount(doc: Model.Document.Document, sa: StoreAccessor) {
        doc.temp_data = new TempData()
    }


    private loadFromBranch(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const content = doc.content as FormModel.KangoSummary.Core
        const values = content.values
        HokanApi.branch.get(doc.patient.branch_id).then(res => {
            values.branch_name = res.data.name || ""
            values.branch_address = res.data.address || ""
            values.branch_tel = res.data.tel || ""
            values.branch_fax = res.data.fax || ""
            sa.updateState()
        }).catch(err => { console.log(err) })
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor, classes: any) {
        const saV = sa.generateChild(["content", "values"])
        //const content = doc.content as FormModel.Keikakusho.Core

        const patId = doc.patient ? doc.patient.id : undefined
        const elements_basic = [
            {
                name: "コピー", content:
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromPatientBasic(doc, sa)}
                        className={classes.loadButton} >記録Iから取得(全体)</Button>
            },
            { name: "生年月日", content: saV.date("pat_birthday") },
            { name: "性別", content: saV.text("pat_sex") },
            { name: "要介護認定の状況", content: saV.list("pat_youkaigo", Model.Patient.YouKaigoType) },
            { name: "公費などの有無", content: saV.text("pat_kouhi") },
            { name: "住所", content: saV.text("pat_address") },
            { name: "宛先", content: saV.orgTanto("houkoku_saki", patId) },
            { name: "作成者", content: saV.text("create_user") }
        ]

        const elements_comment = [
            { name: "主病名", content: saV.text("main_disease") },
            { name: "本人の希望", content: saV.text("kibou_honnin") },
            { name: "家族の希望", content: saV.text("kibou_kazoku") },
            { name: "家族とインフォーマル資源", content: saV.text("kazoku") },
            { name: "生育歴", content: saV.text("seiiku") },
            { name: "病の軌跡・既往歴・入院歴", content: saV.text("kiou") },
            { name: "主治医と連絡先", content: saV.text("shujii") },
            { name: "関係者やサービス種類", content: saV.text("kankeisha") },
            { name: "内服薬と服用方法", content: saV.text("naihuku") },
            { name: "これまでの経過と入院に至る経緯", content: saV.text("nyuin_keii") },
            { name: "看護上の問題とKBS", content: saV.text("kango_mondai") },
            {
                name: "ソーシャルカレンダー", content:
                    <Col2Table disableBGColor={true} body={[
                        { name: "月", content: saV.text("social_monday") },
                        { name: "火", content: saV.text("social_tuesday") },
                        { name: "水", content: saV.text("social_wednesday") },
                        { name: "木", content: saV.text("social_thursday") },
                        { name: "金", content: saV.text("social_friday") },
                        { name: "土", content: saV.text("social_saturday") },
                        { name: "日", content: saV.text("social_sunday") },
                        { name: "備考", content: saV.text("social_coment") }
                    ]}
                        />
                },
            { name: "アレルギー", content: saV.text("arergy") },
            { name: "感染症", content: saV.text("kansen") },
            { name: "予防接種", content: saV.text("yobou") },
            { name: "皮膚", content: saV.text("hihu") },
            { name: "視覚", content: saV.text("shikaku") },
            { name: "聴覚", content: saV.text("choukaku") },
            { name: "会話言語", content: saV.text("kaiwa") },
            { name: "認知", content: saV.text("ninchi") },
            { name: "ドラッグ・嗜好品", content: saV.text("drug") },
            { name: "ADL", content: saV.text("adl") },
            { name: "IADL", content: saV.text("iadl") },
            { name: "使用している医療機器", content: saV.text("iryo_kiki") },
            { name: "その他特記事項", content: saV.text("comment") },
        ]
        const elements_houkoku_moto = [
            {
                name: "コピー", content: <div>
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromBranch(doc, sa)}
                        className={classes.loadButton} >事業所情報から取得</Button>
                </div>
            },
            { name: "事業所名", content: saV.text("branch_name") },
            { name: "住所", content: saV.text("branch_address") },
            { name: "電話", content: saV.text("branch_tel") },
            { name: "FAX", content: saV.text("branch_fax") },
        ]

        const elements = [
            { name: '基本', content: elements_basic },
            { name: '内容', content: elements_comment },
            { name: '事業所', content: elements_houkoku_moto },
        ]

        return elements
    }
}
