import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 86;
  }

  public createContent(): any {
    const res = new FormModel.Fim.Core();
    res.values.create_date = DateUtil.currentDateStr();
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    let total = 0;
    const points = Array(19)
      .fill(0)
      .map((_, i) => `point_${i + 1}`);

    points.forEach((point) => {
      total += parseInt(doc.content.values[point]);
    });

    console.log(total);

    doc.content.values.total_point = total;
  }

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);

    enum SexType {
      None = "未選択",
      Value1 = "男性",
      Value2 = "女性",
    }
    enum PointType {
      None = 1,
      Value2 = 2,
      Value3 = 3,
      Value4 = 4,
      Value5 = 5,
      Value6 = 6,
      Value7 = 7,
    }

    const selfCareList = [
      { name: "食事", num: 1 },
      { name: "整容", num: 2 },
      { name: "清拭", num: 3 },
      { name: "更衣・上半身", num: 4 },
      { name: "更衣・下半身", num: 5 },
      { name: "トイレ動作", num: 6 },
    ];

    const haisetsuList = [
      { name: "排尿管理", num: 7 },
      { name: "排便管理", num: 8 },
    ];

    const ijoList = [
      { name: "ベッド・椅子・車椅子", num: 9 },
      { name: "トイレ", num: 10 },
      { name: "浴槽・シャワー", num: 11 },
    ];

    const idoList = [
      { name: "歩行", num: 12 },
      { name: "車椅子", num: 13 },
      { name: "階段", num: 14 },
    ];

    const communicationList = [
      { name: "理解", num: 15 },
      { name: "表出", num: 16 },
    ];

    const shakaiList = [
      { name: "社会的交流", num: 17 },
      { name: "問題解決", num: 18 },
      { name: "記憶", num: 19 },
    ];

    const genInputGroup = (list: { name: string; num: number }[]) => {
      return list.map((v) => {
        return (
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                {v.name}
                {saV.list(`point_${v.num}`, PointType)}
              </div>
              {saV.text(`comment_${v.num}`, "", "text", "block", true, "30px")}
            </div>
          </div>
        );
      });
    };

    const elements_t = [
      {
        name: "基本情報",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              患者番号
              <div style={{ width: "inherit" }}>{saV.text("pat_number")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              傷病名
              <div style={{ width: "inherit" }}>{saV.text("syoubyoumei")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              生年月日
              {saV.date("pat_birthday")}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              性別
              {saV.list("pat_sex", SexType)}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              年齢
              <div style={{ width: "inherit" }}>
                {saV.text("pat_age", "", "number")}
              </div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              発症日
              {saV.date("hasshoubi")}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              評価担当
              <div style={{ width: "inherit" }}>{saV.text("hyoukatantou")}</div>
            </label>
          </div>
        ),
      },
      {
        name: "内容",
        content: (
          <div style={{ padding: 10 }}>
            <table style={{ borderCollapse: "collapse" }}>
              <tbody>
                <tr>
                  <td rowSpan={2} style={{ border: "1px solid", padding: 5 }}>
                    自立
                  </td>
                  <td style={{ border: "1px solid", padding: 5 }}>
                    ７完全自立
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: 5 }}>
                    ６修正自立（時間がかかる、補助具が必要、安全性の配慮が必要）
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: 5 }}>部分介助</td>
                  <td style={{ border: "1px solid", padding: 5 }}>
                    ５監視、準備（監視、指示、促し、準備）
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ border: "1px solid", padding: 5 }}>
                    介助あり
                  </td>
                  <td style={{ border: "1px solid", padding: 5 }}>
                    ４最小介助（75％以上自分で行う）
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: 5 }}>
                    ３中等度介助（50％以上75％未満を自分で行う）
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ border: "1px solid", padding: 5 }}>
                    完全介助
                  </td>
                  <td style={{ border: "1px solid", padding: 5 }}>
                    ２最大介助（25％以上50％未満を自分で行う）
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: 5 }}>
                    １全介助（25％未満しか自分で行わない）
                  </td>
                </tr>
              </tbody>
            </table>
            <h3>セルフケア</h3>
            {genInputGroup(selfCareList)}
            <h3>排泄コントロール</h3>
            {genInputGroup(haisetsuList)}
            <h3>移乗</h3>
            {genInputGroup(ijoList)}
            <h3>移動</h3>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              主な移動手段
              <div style={{ width: "inherit" }}>{saV.text("idoushudan")}</div>
            </label>
            {genInputGroup(idoList)}
            <h3>コミュニケーション</h3>
            {genInputGroup(communicationList)}
            <h3>社会的認知</h3>
            {genInputGroup(shakaiList)}
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
