export class Company {
    public id: number = 0
    public name: string = ""
    public company_type: string = ""
    public branches: Branch[] = []
}

export class Branch {
    public id: number = 0
    public name: string = ""
    public tel: string = ""
    public fax: string = ""
    public zip: string = ""
    public address: string = ""
    public hoken_jigyosha_no: string = ""
    public ns_count: number = 0
    public pt_count: number = 0
    public features_nursing_station_type: string = ""
    public features_nursing_station_note: string = ""
    public company_id: number = 0
    public users: SimpleUser[] = []
    public kanri_name: string = ""
    public wiseman_id: string = ""
    public kasan: string = "{}"
    public kasan_obj: BranchKasan = new BranchKasan()
    public saigai_pdf_url: string = ""
    public claim_pdf_url: string = ""
    public jiko_pdf_url: string = ""
}

export class BranchKasan {
    public is_kanri_ryouyou: boolean = false // "管理療養費",
    public is_kanri_ryouyou_kinou1: boolean = false //"機能強化型訪問看護管理療養費１",
    public is_kanri_ryouyou_kinou2: boolean = false //"機能強化型訪問看護管理療養費２",
    public is_kanri_ryouyou_kinou3: boolean = false //"機能強化型訪問看護管理療養費３",
    public is_minashi: boolean = false // "みなし訪問看護",
    public is_24h: boolean = false // "２４時間対応体制加算",
    public is_tokubetsu_chiiki1: boolean = false // "特別地域訪問看護加算１",
    public is_tokubetsu_chiiki2: boolean = false // "特別地域訪問看護加算２",
    public is_shoukibo1: boolean = false // "訪問看護小規模事業所加算１",
    public is_shoukibo2: boolean = false // "訪問看護小規模事業所加算２",
    public is_taisei_kyouka1: boolean = false // "訪問看護体制強化加算Ⅰ",
    public is_taisei_kyouka2: boolean = false // "訪問看護体制強化加算Ⅱ",
    public is_teikyou_taisei1: boolean = false // "訪問看護サービス提供体制加算１",
    public is_teikyou_taisei2: boolean = false // "訪問看護サービス提供体制加算２"
}

export class User {
    public id: number = 0
    public branch_id: number = 0
    public branch: Branch | null = null
    public email: string = ""
    public tel1: string = ""
    public tel2: string = ""
    public password: string = ""
    public name: string = ""
    public sex: string = ""
    public privilege: string = ""
    public birthday: string = ""
    public keiken_shouni: boolean = false
    public keiken_seishin: boolean = false
    public keiken_kanwa: boolean = false
    public keiken_kaigo: boolean = false
    public keiken_cm: boolean = false
    public nyusha: string = ""
    public year_of_rinsho: number = 0
    public year_of_houmon: number = 0
    public wiseman_id: string | null = null
    public shokuin_code: string = ""
    public iryo_shokuin_code: string = ""
    public work_style: string = ""
    public role: string = ""
    public shikaku: string = ""
    public use_mfa: boolean = false
    public kana: string = ""

    public static getShikakuName(role: string) {
        const res = UserShikakuList.find(x => x.value == role)
        return res ? res.label : "0"
    }

}

export class SimpleUser {
    public id: number = 0
    public branch_id: number = 0
    public name: string = ""
    public wiseman_id: string | null = null
    public kana: string = ""
}

export const UserShikakuList = [
    { label: '未選択', value: "0" },
    { label: '一時停止', value: "98" },
    { label: '看護師', value: "3" },
    { label: '准看護師', value: "4" },
    { label: '理学療法士(PT)', value: "11" },
    { label: '作業療法士(OT)', value: "12" },
    { label: '言語聴覚士(ST)', value: "13" },
    { label: '事務など', value: "99" },
    { label: '医師', value: "1" },
    { label: '歯科医師', value: "2" },
    { label: '介護支援専門員', value: "5" },
    { label: '訪問介護員', value: "6" },
    { label: '介護福祉士(CW)', value: "7" },
    { label: '社会福祉士(SW)', value: "8" },
    { label: '精神保健福祉士(PSW)', value: "9" },
    { label: '医療ソーシャルワーカー(MSW)', value: "10" },
    { label: '視能訓練士(ORT)', value: "14" },
    { label: '居宅介護従業者', value: "15" },
    { label: '重度訪問介護従業者', value: "16" },
    { label: '行動支援従業者', value: "17" },
    { label: '外出介護従業者', value: "18" },
    { label: '栄養士', value: "19" },
    { label: '管理栄養士', value: "20" },
    { label: '薬剤師', value: "21" },
]