import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 88;
  }

  public createContent(): any {
    const res = new FormModel.Mna.Core();
    res.values.create_date = DateUtil.currentDateStr();
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    if (
      doc.content.values.point_f1 !== 0 &&
      doc.content.values.point_f2 !== 0
    ) {
      alert("F1, F2はどちらかのみ入力してください");
    }
    let total = 0;

    total += doc.content.values.point_a;
    total += doc.content.values.point_b;
    total += doc.content.values.point_c;
    total += doc.content.values.point_d;
    total += doc.content.values.point_e;
    total += doc.content.values.point_f1;
    total += doc.content.values.point_f2;

    doc.content.values.screening_point = total;
  }

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);

    enum SexType {
      None = "未選択",
      Value1 = "男性",
      Value2 = "女性",
    }

    enum Atype {
      None = 0,
      Value1 = 1,
      Value2 = 2,
    }

    enum Btype {
      None = 0,
      Value1 = 1,
      Value2 = 2,
      Value3 = 3,
    }

    enum Ctype {
      None = 0,
      Value1 = 1,
      Value2 = 2,
    }

    enum Dtype {
      None = 0,
      Value2 = 2,
    }

    enum Etype {
      None = 0,
      Value1 = 1,
      Value2 = 2,
    }

    enum F1type {
      None = 0,
      Value1 = 1,
      Value2 = 2,
      Value3 = 3,
    }

    enum F2type {
      None = 0,
      Value3 = 3,
    }

    const elements_t = [
      {
        name: "基本情報",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              性別
              <div>{saV.list("sex", SexType)}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              年齢<div>{saV.text("age", undefined, "number")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              体重<div>{saV.text("weight", undefined, "number")}</div>kg
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              身長<div>{saV.text("height", undefined, "number")}</div>cm
            </label>
          </div>
        ),
      },
      {
        name: "スクリーニング",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              A 過去
              3ヶ月間で食欲不振、消化器系の問題、そしゃく・嚥下困難などで食事量が減少しましたか？
              <div>{saV.list("point_a", Atype)}</div>
            </label>
            <p style={{ marginBottom: 10, marginTop: 0, fontSize: "small" }}>
              0 = 著しい食事量の減少
              <br />
              1 = 中等度の食事量の減少
              <br />2 = 食事量の減少なし
            </p>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              B 過去 3 ヶ月間で体重の減少がありましたか？
              <div>{saV.list("point_b", Btype)}</div>
            </label>
            <p style={{ marginBottom: 10, marginTop: 0, fontSize: "small" }}>
              0 = 3 kg 以上の減少
              <br />
              1 = わからない
              <br /> 2 = 1～3 kg の減少
              <br /> 3 = 体重減少なし
            </p>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              C 自力で歩けますか？
              <div>{saV.list("point_c", Ctype)}</div>
            </label>
            <p style={{ marginBottom: 10, marginTop: 0, fontSize: "small" }}>
              0 = 寝たきりまたは車椅子を常時使用
              <br />
              1 = ベッドや車椅子を離れられるが、歩いて外出はできない
              <br />2 = 自由に歩いて外出できる
            </p>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              D 過去 3 ヶ月間で精神的ストレスや急性疾患を経験しましたか？
              <div>{saV.list("point_d", Dtype)}</div>
            </label>
            <p style={{ marginBottom: 10, marginTop: 0, fontSize: "small" }}>
              0 = はい
              <br />
              2 = いいえ
              <br />
            </p>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              E 神経・精神的問題の有無
              <div>{saV.list("point_e", Etype)}</div>
            </label>
            <p style={{ marginBottom: 10, marginTop: 0, fontSize: "small" }}>
              0 = 強度認知症またはうつ状態
              <br />
              1 = 中程度の認知症
              <br />2 = 精神的問題なし
            </p>
            <div style={{ fontWeight: "bold", marginBottom: 10 }}>
              BMI が測定できない方は、F1 の代わりに F2 に回答してください。 BMI
              が測定できる方は、F1 のみに回答し、F2 には記入しないでください。
            </div>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              F1 BMI
              <div>
                {saV.list(
                  "point_f1",
                  F1type,
                  doc.content.values.point_f2 !== 0
                )}
              </div>
            </label>
            <p style={{ marginBottom: 10, marginTop: 0, fontSize: "small" }}>
              0 = BMI が19 未満
              <br />
              1 = BMI が19 以上、21 未満
              <br />2 = BMI が21 以上、23 未満
              <br /> 3 = BMI が 23 以上
            </p>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              F2 ふくらはぎの周囲長(cm)
              <div>
                {saV.list(
                  "point_f2",
                  F2type,
                  doc.content.values.point_f1 !== 0
                )}
              </div>
            </label>
            <p style={{ marginBottom: 10, marginTop: 0, fontSize: "small" }}>
              0 = 31cm未満
              <br />3 = 31cm以上
            </p>
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
